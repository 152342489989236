import {
  Vue, Options
} from 'vue-class-component'
import swal from 'sweetalert'
import Header from '@/components/Header.vue'
import { orderFactory } from '@/http/order'
import { billFactory } from '@/http/bill'
import { masterDataFactory } from '@/http/master-data'

@Options({
  components: {
    Header
  },
  data() {
    return {
      va_file: '',
      expired_file: '',
      bill_options: [],
      selected_bill: 0,
    }
  },
  methods: {
    handleVAFileUpload: function () {
      this.va_file = this.$refs.va_file.files[0]
    },
    handleExpireFileUpload: function () {
      this.expired_file = this.$refs.expired_file.files[0]
    },
    importFile: function (fileName: string) {
      if(this[fileName] == '') {
        swal("Harap pilih berkas yang akan diimpor", "", "error")
        return
      }
      
      const formData = new FormData();
      formData.append('excel', this[fileName]);
      
      if(fileName == 'va_file') {
        if(!this.selected_bill) {
          swal("Harap pilih tagihan yang akan digunakan", "", "error")
          return
        }
        formData.append('spp_bill_id', this.selected_bill);

        Promise.resolve(orderFactory.importVAOrder(formData))
          .then((response) => {
            //check if is set errmessage
            if (response.data.errmessage) {
              swal({
                title: "Error",
                icon: "error",
                // className: 'swal-large',
                closeOnClickOutside: false,
                content: {
                  element: "div",
                  attributes: {
                    innerHTML: '<ul style="text-align: left">' + response.data.errmessage.map((err: any) => `<li>${err}</li>`).join('') + '</ul>',
                    style: "text-align: left"
                  }
                }
                // button: "OK",
              })
              this.va_file = ''
              //reset #import-file-expired input file
              this.$refs.va_file.value = ''
              this.selected_bill = 0  
              return
            }
            var failmessages = '<ul style="text-align: left">'
            var successmessages = '<ul style="text-align: left">'
            var successCount = 0
            var failCount = 0
            var result_by_nis = response.data.result_by_nis
            //looping result by nis with key as nis and boolean value
            for (var key in result_by_nis) {
              if (result_by_nis[key]) {
                successmessages += `<li>NIS ${key} berhasil diimpor order</li>`
                successCount++
              } else {
                failmessages += `<li>NIS ${key} gagal diimpor order</li>`
                failCount++
              }
            }
            failmessages += '</ul>'
            successmessages += '</ul>'
            var total = successCount + failCount
            var messages = `
              <h3>Result</h3>
              <p>Total baris: ${total}</p>
              <p>Impor order berhasil: ${successCount}</p>
              <div style="max-height: 200px; overflow-y: auto; border: 1px solid #ccc; padding: 5px;">
                ${successmessages}
              </div><br>
              <p>Impor order gagal: ${failCount}</p>
              <div style="max-height: 200px; overflow-y: auto; border: 1px solid #ccc; padding: 5px;">
                ${failmessages}
              </div>
            `

            // swal(`Berhasil membuat ${response.data.order.length} order`, "", "success")
            swal({
              title: "Import VA Order",
              icon: "success",
              className: 'swal-large',
              closeOnClickOutside: false,
              content: {
                element: "div",
                attributes: {
                  innerHTML: messages + '</ul>',
                  style: "text-align: left"
                }
              }
              // button: "OK",
            })
            this.va_file = ''
            //reset #import-file input file
            this.$refs.va_file.value = ''
            this.selected_bill = 0
          }).catch((e) => {
            this.va_file = ''
            //reset #import-file input file
            this.$refs.va_file.value = ''
            this.selected_bill = 0
            console.log(e)
            swal("Gagal melakukan request", "", "error")
            console.log(e)
          }
        )
      }
      else if(fileName == 'expired_file') {
        Promise.resolve(orderFactory.importVAExpired(formData))
          .then((response) => {
            //check if is set errmessage
            if (response.data.errmessage) {
              swal({
                title: "Error",
                icon: "error",
                // className: 'swal-large',
                closeOnClickOutside: false,
                content: {
                  element: "div",
                  attributes: {
                    innerHTML: '<ul style="text-align: left">' + response.data.errmessage.map((err: any) => `<li>${err}</li>`).join('') + '</ul>',
                    style: "text-align: left"
                  }
                }
                // button: "OK",
              })
              this.expired_file = ''
              //reset #import-file-expired input file
              this.$refs.expired_file.value = ''
              this.selected_bill = 0  
              return
            }
            var failmessages = '<ul style="text-align: left">'
            var successmessages = '<ul style="text-align: left">'
            var successCount = 0
            var failCount = 0
            var result_by_nis = response.data.result_by_nis
            //looping result by nis with key as nis and boolean value
            for (var key in result_by_nis) {
              if (result_by_nis[key]) {
                successmessages += `<li>NIS ${key} berhasil diimpor expired</li>`
                successCount++
              } else {
                failmessages += `<li>NIS ${key} gagal diimpor expired</li>`
                failCount++
              }
            }
            failmessages += '</ul>'
            successmessages += '</ul>'
            var total = successCount + failCount
            var messages = `
              <h3>Result</h3>
              <p>Total baris: ${total}</p>
              <p>Impor expired berhasil: ${successCount}</p>
              <div style="max-height: 200px; overflow-y: auto; border: 1px solid #ccc; padding: 5px;">
                ${successmessages}
              </div><br>
              <p>Impor expired gagal: ${failCount}</p>
              <div style="max-height: 200px; overflow-y: auto; border: 1px solid #ccc; padding: 5px;">
                ${failmessages}
              </div>
            `

            // swal(`Berhasil membuat ${response.data.order.length} order`, "", "success")
            swal({
              title: "Import VA Expired",
              icon: "success",
              className: 'swal-large',
              closeOnClickOutside: false,
              content: {
                element: "div",
                attributes: {
                  innerHTML: messages + '</ul>',
                  style: "text-align: left"
                }
              }
              // button: "OK",
            })

            this.expired_file = ''
            //reset #import-file-expired input file
            this.$refs.expired_file.value = ''
            this.selected_bill = 0
          }).catch((e) => {
            this.expired_file = ''
            //reset #import-file-expired input file
            this.$refs.expired_file.value = ''
            this.selected_bill = 0
            swal("Gagal melakukan request", "", "error")
            console.log(e)
          }
        )
      }
    },
    downloadTemplate: function () {
      Promise.resolve(orderFactory.downloadVAFileTemplate())
      .then(() => {

      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    getSchoolYear: function() {
      Promise.resolve(masterDataFactory.master_datas({ school_years: "1" }))
      .then(result => {
        const schoolYears = result.data.school_years
        schoolYears.forEach((school_year: any) => {
          if (school_year.status == "Active") {
            return school_year.school_year_id
          }
        })
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    getBill: function() {
      var param = {
        school_year_id: this.getSchoolYear()
      }
      Promise.resolve(billFactory.bill(param))
      .then(result => {
        this.bill_options = result.data.bills
      })
      .catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
  },
  async mounted() {
    await this.getBill()
  }
})

export default class VAImport extends Vue {}
