import axios from 'axios'

class OrderFactory {

  async orders (params: any) {
    try {
      let url = '/orders'
      var response = await axios.get(url, { params })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async detailOrder (id: any) {
    try {
      let url = `/orders/${id}`
      var response = await axios.get(url)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async exportOrder (data: any) {
    try {
      let url = '/orders/export'
      var response = await axios.get(url, {
          responseType: 'arraybuffer',
          params: {
            status: data.status,
            date_from: data.date_from,
            date_to: data.date_to,
            keyword: data.keyword,
            order_by: data.order_by,
            order_at: data.order_at,
            child_user_id: data.child_user_id
        }
      })
      let headers = response.headers

      let filename = 'Ekspor_Riwayat_Pembayaran.xlsx'
      //if content disposition is present
      if (headers['content-disposition']){
        filename = headers['content-disposition'].split('filename=')[1]
      }

      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      link.click()
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async importOrder (data: any) {
    try {
      let url = '/orders/import'
      var response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async importVAOrder (data: any) {
    try {
      let url = '/orders/import-va'
      var response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async importVAExpired (data: any) {
    try {
      let url = '/orders/import-va-expired'
      var response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async savePayment (data: any) {
    try {
      let url = '/orders/payment'
      var response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async templateImport (data: any) {
    try {
      let url = '/orders/template'
      var response = await axios.get(url, {
          responseType: 'arraybuffer',
          params: { }
      })
      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Template_Impor_Pembayaran.xlsx'
      link.click()
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async downloadVAFileTemplate () {
    try {
      let url = '/orders/import-va/template'
      var response = await axios.get(url, {
          responseType: 'arraybuffer',
          params: { }
      })
      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Template_VA_Orders.xlsx'
      link.click()
    } catch (error) {
      console.error(error)
      return false
    }
  }
}


export let orderFactory = new OrderFactory()
