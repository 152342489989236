import axios from 'axios'

class BillFactory {

  async bill (data: any) {
    try {
      let url = '/bills'
      var response = await axios.get(url, {
        params: {
          school_year_id: data.school_year_id
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async billDetail (id: any) {
    try {
      let url = `/bills/${id}`
      var response = await axios.get(url)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async createBill (data: any) {
    try {
      let url = '/bills'
      var response = await axios.post(url, data)
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async updateBill (id: any, data: any) {
    try {
      let url = `/bills/${id}`
      var response = await axios.put(url, data)
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async generateBill (id: any) {
    try {
      let url = `/bills/${id}/users`
      var response = await axios.post(url)
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async generateBillByUserIds (id: any, student_ids: any) {
    try {
      let url = `/bills/${id}/users/user_ids`
      var data = {
        student_ids: student_ids
      }
      var response = await axios.post(url, data)
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async downloadManualPaymentFileTemplate () {
    try {
      let url = '/bills/manual-payment/template'
      var response = await axios.get(url, {
          responseType: 'arraybuffer',
          params: { }
      })
      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Template_Pelunasan_Manual.xlsx'
      link.click()
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async importManualPaymentFile (data: any) {
    try {
      let url = '/bills/manual-payment'
      var response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

}


export let billFactory = new BillFactory()
