// @ts-nocheck

import {
  Vue, Options
} from 'vue-class-component'
import swal from 'sweetalert'
import * as bootstrap from 'bootstrap'
import Header from '@/components/Header.vue'
import { orderFactory } from '@/http/order'
import { userFactory } from '@/http/user'
import { masterDataFactory } from '@/http/master-data'
import { billFactory } from '@/http/bill'
import Utils from '@/utils/utils'
import Datepicker from 'vue3-datepicker'
import constants from '@/constants/constants'
import VPagination from '@hennge/vue3-pagination'
import { setupFactory } from '@/http/setup'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'

@Options({
  components: {
    Header,
    Datepicker,
    VPagination
  },
  data() {
    return {
      orders: [],
      order: [],
      student: [],
      billUserInstallments: [],
      orderStatuses: {
        "0":"Semua Status",
        "310":"Aktif",
        "320":"Belum Bayar",
        "330":"Terbayar",
        "331":"Manual",
        "340":"Dibatalkan",
        "341":"Kedaluwarsa"
      },
      classes: [],
      param: {
        status: 0,
        date_from: null,
        date_to: null,
        keyword: "",
        offset: 0,
        limit: 20,
        order_by: "desc",
        order_at: "created_at",
        child_user_id: "",
        class_id: "",
        payment_date: null,
        total_payment: null,
        reff_no: null,
      },
      schoolYears: [],
      months: [],
      data_master: {
        school_years: "1",
        months: "1",
        classes: "1",
      },
      totalPaid: 0,
      totalBill: 0,
      detailReady: false,
      loadingSave: false,
      paymentReady: false,
      currentPage: 1,
      totalPage: 1,
      searchField:"",
      modal:false,
      students: [],
      constants: constants,
      file: '',
      paymentFile: '',
      import_result: {
        rejected_orders: [],
        saved_orders: [],
      },
      instance: '',
      manualPaymentByVA : false,
      billOption: [],
      spp_bill_id2: '',
    }
  },
  watch: {
    '$route.query': {
      handler: 'onPageQueryChange',
      deep: true
    }
  },
  methods: {
    setRouter: function () {
      this.$router.push({
        path: '/payment',
        query: {
          child_user_id: this.param.child_user_id,
          page: this.currentPage,
        }
      })
    },
    onPageChanged(page:any) {
      if (page) {
        this.currentPage = page
      }
      page = Math.round(this.currentPage)
      if (this.totalPage >= page) {
        this.$router.push({ query: { page } })
      }
    },
    onPageQueryChange() {
      this.param.offset = (this.currentPage - 1) * parseInt(this.param.limit)
      this.loadData()
    },
    async getSuggestions(){
      if (this.searchField.length > 3) {
        var param = {
          "keyword": this.searchField
        }
        Promise.resolve(userFactory.students(param))
        .then(result => {
            this.students = result.data.users[0]
            this.students.forEach((value:any) => {
              if (value.child_name != null) {
                value.name = value.child_name
                value.user_id = value.child_user_id
              }
            })
        }).catch((e) => {
          swal("Gagal melakukan request", "", "error")
          console.log(e)
          this.students = []
        })
      } else {
        this.students = []
      }
    },
    setSearch(search:any){
      this.searchField = search.name
      this.param.child_user_id = search.user_id
      this.modal = false
      this.setRouter()
    },
    getMonth: function(date:any) {
      if (date != '' && date != undefined) {
        const d = date.split("-")
        const month  = d[2]
        return this.months[month].id
      } else {
        return ''
      }
    },
    beautifyDatetime: function(date:any) {
      return Utils.beautifyDatetime(date)
    },
    toLocaleString: function(val:any) {
      return Utils.toLocaleString(val)
    },
    formatDate: function(date:any) {
      var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()
      if (month.length < 2)
          month = '0' + month
      if (day.length < 2)
          day = '0' + day
      return [year, month, day].join('-')
    },
    orderDetail: function(order_id:any) {
      this.detailReady = false
      Promise.all([
        orderFactory.detailOrder(order_id)
      ]).then(results => {
        this.order = results[0].data.order
        this.student = results[0].data.student
        this.billUserInstallments = results[0].data.bill_user_installments
        this.detailReady = true
        this.showModal()
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
      return
    },
    exportOrder: function() {
      var param = {
        status: this.param.status,
        date_from: null,
        date_to: null,
        keyword: this.param.keyword,
        order_by: "desc",
        order_at: "created_at",
        child_user_id: this.param.child_user_id
      }
      if (this.param.date_from) {
        param.date_from = this.formatDate(this.param.date_from)
      }
      if (this.param.date_to) {
        param.date_to = this.formatDate(this.param.date_to)
      }
      Promise.resolve(orderFactory.exportOrder(param))
      .then(() => {

      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    templateImport: function() {
      var param = {}
      Promise.resolve(orderFactory.templateImport(param))
      .then(() => {

      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    handleFileUpload: function () {
      this.file = this.$refs.file.files[0]
    },
    handlePaymentUpload: function (file) {
      this.paymentFile = file.files[0]
    },
    showImportModal: function() {
      this.import_result.rejected_orders = []
      this.import_result.saved_orders = []
      this.importModal.show()
    },
    importOrder: function () {
      if (this.file == '') {
        swal("Harap pilih berkas yang akan diimpor", "", "error")
        return
      }

      let formData = new FormData();
      formData.append('excel', this.file);
      Promise.resolve(orderFactory.importOrder(formData))
        .then((response) => {
          if (response.data.rejected_orders) {
            this.import_result.rejected_orders = response.data.rejected_orders
          }
          if (response.data.saved_orders) {
            this.import_result.saved_orders = response.data.saved_orders
          }
        }).catch((e) => {
          swal("Gagal melakukan request", "", "error")
          console.log(e)
        })
    },
    editPayment: function(order_id:any) {
      this.detailReady = false
      Promise.all([
        orderFactory.detailOrder(order_id)
      ]).then(results => {
        this.order = results[0].data.order
        this.student = results[0].data.student
        this.billUserInstallments = results[0].data.bill_user_installments
        this.detailReady = true

        // set order.total_payment as default paid value
        this.param.total_payment = this.order.total_payment

        this.showPaymentModal()
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
      return
    },
    savePayment: function() {
      if (this.param.payment_date == null || this.param.payment_date == '') {
        swal("Harap isi Tanggal Pembayaran", "", "error")
        return
      }
      else if (this.param.total_payment == null || isNaN(this.param.total_payment) || this.param.total_payment <= 0) {
        swal("Harap isi Nominal Pembayaran", "", "error")
        return
      }
      else if (this.param.reff_no == null || this.param.reff_no == '') {
        swal("Harap isi Nomor Referensi", "", "error")
        return
      }
      else if (this.paymentFile == null || this.paymentFile == '') {
        swal("Harap pilih berkas yang akan diimpor", "", "error")
        return
      }

      this.loadingSave = true
      let formData = new FormData();

      formData.append('id', this.order.id);
      formData.append('payment_date', this.formatDate(this.param.payment_date));
      formData.append('total_payment', this.param.total_payment);
      formData.append('reff_no', this.param.reff_no);
      formData.append('file', this.paymentFile);

      Promise.resolve(orderFactory.savePayment(formData))
      .then((response) => {
        if (response.error == false) {
          swal("Sukses menyimpan pembayaran", "", "success")
          this.hidePaymentModal()
          this.loadData()
          this.loadingSave = false
        }
        else{
          let messages = response.messages || [];

          if(messages.length){
            messages = messages.join('<br>')
          }
          else{
            messages = 'Gagal melakukan request'
          }

          swal(messages, "", "error")
          this.loadingSave = false
        }
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
        this.loadingSave = false
      })
    },
    loadData: function(type:any) {
      if (type === 'refresh') {
        this.onPageChanged(1)
      }

      if (!this.param.child_user_id) {
        var child_user_id = this.$route.query.child_user_id
        this.param.child_user_id = child_user_id
      }
      /* if (this.searchField.length == 0) {
        this.param.child_user_id = ''
      } */

      var param = {
        status: this.param.status,
        date_from: "",
        date_to: "",
        keyword: this.param.keyword,
        offset: this.param.offset,
        limit: this.param.limit,
        order_by: "desc",
        order_at: "created_at",
        child_user_id: this.param.child_user_id,
        class_id: this.param.class_id,
      }
      if (this.param.date_from) {
        param.date_from = this.formatDate(this.param.date_from)
      }
      if (this.param.date_to) {
        param.date_to = this.formatDate(this.param.date_to)
      }
      this.totalPaid  = 0
      Promise.all([
        orderFactory.orders(param)
      ]).then(results => {
        this.orders = results[0].data.orders
        this.totalBill = results[0].data.total_rows
        this.totalPaid = results[0].data.total_paid?.total_paid
        var limit = parseInt(this.param.limit)
        var totalPage = Math.round((this.totalBill + limit - 1) / limit)
        if ((totalPage * limit - this.totalBill) >= limit) {
          totalPage = Math.round(this.totalBill / limit)
        }
        this.totalPage = totalPage

        if (!this.searchField && this.param.child_user_id) {
          if (this.orders[0].student) {
            this.searchField = this.orders[0].student.name
          }
        }
        this.instance = String(localStorage.getItem('instance'))
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    loadDataMaster: function() {
      Promise.all([
        masterDataFactory.master_datas(this.data_master)
      ]).then(results => {
        this.schoolYears = results[0].data.school_years
        this.months = results[0].data.months
        this.classes = results[0].data.classes
        this.schoolYears.forEach((school_year:any) => {
          if (school_year.status == "Active") {
            this.param.school_year_id = school_year.school_year_id
            this.school_year_name = school_year.name
          }
        })
        this.loadData()
        this.getBill()
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    showModal: function() {
      this.myModal.show()
    },
    hideModal: function() {
      this.myModal.hide()
    },
    showPaymentModal: function() {
      this.paymentModal.show()
    },
    hidePaymentModal: function() {
      this.paymentModal.hide()
    },
    getBill: function() {
      var param = {
        school_year_id: this.param.school_year_id
      }
      Promise.resolve(billFactory.bill(param))
      .then(result => {
        this.billOption = result.data.bills
      })
      .catch((e) => {
        console.log(e)
      })
    },
    downloadTemplate: function () {
      Promise.resolve(billFactory.downloadManualPaymentFileTemplate())
      .then(() => {

      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    handlePaymentFileUpload: function () {
      this.payment_file = this.$refs.payment_file.files[0]
    },
    importPaymentFile: function () {
      if (this.payment_file == '' && this.$refs.payment_file.value != null) {
        swal("Harap pilih berkas yang akan diimpor", "", "error")
        return
      }
      if(!this.spp_bill_id2) {
        swal("Harap pilih tagihan yang akan digunakan", "", "error")
        return
      }

      const formData = new FormData();
      formData.append('excel', this.payment_file);
      formData.append('spp_bill_id', this.spp_bill_id2);

      Promise.resolve(billFactory.importManualPaymentFile(formData))
        .then((response) => {
          //check if is set errmessage
          if (response.data.errmessage) {
            swal({
              title: "Error",
              icon: "error",
              // className: 'swal-large',
              closeOnClickOutside: false,
              content: {
                element: "div",
                attributes: {
                  innerHTML: '<ul style="text-align: left">' + response.data.errmessage.map((err: any) => `<li>${err}</li>`).join('') + '</ul>',
                  style: "text-align: left"
                }
              }
              // button: "OK",
            })
            this.payment_file = ''
            this.spp_bill_id2 = ''
            this.$refs.payment_file.value = ''
            return
          }
          var failmessages = '<ul style="text-align: left">'
          var successmessages = '<ul style="text-align: left">'
          // var successCount = 0
          // var failCount = 0
          //looping response.data.successes
          for (var i = 0; i < response.data.successes.length; i++) {
            successmessages += `<li>${response.data.successes[i]}</li>`
            // successCount++
          }

          //looping response.data.errors
          for (var i = 0; i < response.data.errors.length; i++) {
            failmessages += `<li>${response.data.errors[i]}</li>`
            // failCount++
          }

          failmessages += '</ul>'
          successmessages += '</ul>'
          // var total = successCount + failCount
          var messages = `
            <h3>Result</h3>
            <p>Keterangan Berhasil</p>
            <div style="max-height: 200px; overflow-y: auto; border: 1px solid #ccc; padding: 5px;">
              ${successmessages}
            </div><br>
            <p>Keterangan Gagal</p>
            <div style="max-height: 200px; overflow-y: auto; border: 1px solid #ccc; padding: 5px;">
              ${failmessages}
            </div>
          `

          // swal(`Berhasil membuat ${response.data.order.length} order`, "", "success")
          swal({
            title: "Import Pelunasan Manual",
            icon: "success",
            className: 'swal-large',
            closeOnClickOutside: false,
            content: {
              element: "div",
              attributes: {
                innerHTML: messages + '</ul>',
                style: "text-align: left"
              }
            }
            // button: "OK",
          })
          this.payment_file = ''
          this.spp_bill_id2 = ''
          this.$refs.payment_file.value = ''
          //close modal
          this.pelunasanmanual.hide()
        }).catch((e) => {
          this.payment_file = ''
          this.spp_bill_id2 = ''
          this.$refs.payment_file.value = ''
          //close modal
          this.pelunasanmanual.hide()
          swal("Gagal melakukan request", "", "error")
          console.log(e)
        }
      )
    },
  },
  async created() {
    Promise.resolve(setupFactory.getFeatureConfigs({feature_configs: ['disable_spp_bill_checkboxes', 'spp_manual_payment_by_va']})).then(
      (res) => {
        if('disable_spp_bill_checkboxes' in res.data.feature_configs && res.data.feature_configs.disable_spp_bill_checkboxes == 'true'){
          this.orderStatuses = {
            "0":"Semua Status",
            "310":"Aktif",
            "320":"Belum Bayar",
            "330":"Terbayar",
            "340":"Dibatalkan"
          }
        }
        if('spp_manual_payment_by_va' in res.data.feature_configs && res.data.feature_configs.spp_manual_payment_by_va == 'true'){
          this.manualPaymentByVA = true
        }
      }
    )
  },
  async mounted () {
    await this.loadDataMaster()

    var myModal = document.getElementById('orderModal')
    if (myModal != null) {
      this.myModal = new bootstrap.Modal(myModal)
    }

    var importModal = document.getElementById('importModal')
    if (importModal != null) {
      this.importModal = new bootstrap.Modal(importModal)
    }

    var paymentModal = document.getElementById('paymentModal')
    if (paymentModal != null) {
      this.paymentModal = new bootstrap.Modal(paymentModal)
    }

    var pelunasanmanual = document.getElementById('pelunasanmanual')
    if (pelunasanmanual != null) {
      this.pelunasanmanual = new bootstrap.Modal(pelunasanmanual)
    }
  },
})

export default class Payment extends Vue {}
