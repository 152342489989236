import {
    Vue, Options
} from 'vue-class-component'
import swal from 'sweetalert'
import Header from '@/components/Header.vue'
import { masterDataFactory } from '@/http/master-data'

@Options({
    components: {
        Header
    },
    data() {
        return {
            configs: [],
            counter: 0
        }
    },
    methods: {
    },
    watch: {
        configs: {
            handler: function (val) {
                //post to api
                if (this.counter > 0) {
                    Promise.resolve(masterDataFactory.setConfigs({configs: val})).then(
                        (res) => {
                            swal("Success", "Configs updated", "success")
                        }
                    )
                }
                this.counter++
            },
            deep: true
        }
    },
    async mounted() {
        Promise.resolve(masterDataFactory.getConfigs()).then(
            (res) => {
                this.configs = res.data.configs
            }
        )
    }
})

export default class VAImport extends Vue { }
