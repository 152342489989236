export default {
    monthArr: function() {
      return {
        1: 'Januari',
        2: 'Februari',
        3: 'Maret',
        4: 'April',
        5: 'Mei',
        6: 'Juni',
        7: 'Juli',
        8: 'Agustus',
        9: 'September',
        10: 'Oktober',
        11: 'November',
        12: 'Desember'
      }
    },

    beautifyDate: function(date) {
      if (!date) return ''

      const split = date.split(' ')
      const datecomp = split[0].split('-')
      const year = datecomp[0]
      const month = datecomp[1]
      const tanggal = datecomp[2]
      var monthArr = this.monthArr()

      return tanggal + ' ' + monthArr[parseInt(month)] + ' ' + year
    },

    beautifyDatetime (date) {
      if (!date) return ''

      const split = date.split(' ')
      const datecomp = split[0].split('-')
      const timecomp = split[1].split(':')
      const year = datecomp[0]
      const month = datecomp[1]
      const tanggal = datecomp[2]
      const jam = timecomp[0]
      const menit = timecomp[1]

      var monthArr = this.monthArr()

      return tanggal + ' ' + monthArr[parseInt(month)] + ' ' + year + ' (' + jam + '.' + menit + ')'
    },

    datetoJSONLocal (date) {
      var local = new Date(date);
      local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      return local.toJSON().slice(0, 10);
    },

    toLocaleString (val) {
      if (!val) return "-"
      val = parseInt(val)
      return val.toLocaleString()
    },

    indexSearch (array, key, id) {
      return array.map(function(el) {
        return el[key];
      }).indexOf(id);
    }
}
