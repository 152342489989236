import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelText as _vModelText } from "vue"

const _hoisted_1 = { class: "row mb-3" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "card h-100" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      title: "Konfigurasi",
      link1: "Konfigurasi"
    }),
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, [
            _createVNode("div", _hoisted_5, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.configs, (config, i) => {
                return (_openBlock(), _createBlock("div", {
                  class: "col-12 d-flex justify-content-between py-3",
                  key: i,
                  style: {"border-bottom":"1px solid #e9ecef"}
                }, [
                  _createVNode("div", null, _toDisplayString(config.conf_name.replace(/_/g, ' ').toUpperCase().slice(4)), 1),
                  _createVNode("div", null, [
                    (config.conf_isBoolean == 1)
                      ? (_openBlock(), _createBlock("div", _hoisted_6, [
                          _withDirectives(_createVNode("input", {
                            type: "radio",
                            id: 'true-'+i,
                            value: "true",
                            "onUpdate:modelValue": ($event: any) => (config.conf_value = $event),
                            class: "me-1"
                          }, null, 8, ["id", "onUpdate:modelValue"]), [
                            [_vModelRadio, config.conf_value]
                          ]),
                          _createVNode("label", {
                            for: 'true-'+i,
                            class: "me-2"
                          }, " True", 8, ["for"]),
                          _withDirectives(_createVNode("input", {
                            type: "radio",
                            id: 'false-'+i,
                            value: "false",
                            "onUpdate:modelValue": ($event: any) => (config.conf_value = $event),
                            class: "me-1"
                          }, null, 8, ["id", "onUpdate:modelValue"]), [
                            [_vModelRadio, config.conf_value]
                          ]),
                          _createVNode("label", {
                            for: 'false-'+i
                          }, "False", 8, ["for"])
                        ]))
                      : (_openBlock(), _createBlock("div", _hoisted_7, [
                          _withDirectives(_createVNode("input", {
                            type: "text",
                            "onUpdate:modelValue": ($event: any) => (config.conf_value = $event),
                            class: "form-control form-control-sm"
                          }, null, 8, ["onUpdate:modelValue"]), [
                            [
                              _vModelText,
                              config.conf_value,
                              void 0,
                              { lazy: true }
                            ]
                          ])
                        ]))
                  ])
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ])
  ], 64))
}